<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
				<div style="padding: 30px 20px;">
					<div>
						<a-button style="width: 160px;" icon="plus" type="primary" @click="addAct()">添加优惠券</a-button>
					</div>
				
					<div class="mt20">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
						</div>

					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		<div v-if="showForm">
			<edit-coupon :visible="showForm" @cancel="closedAct"></edit-coupon>
		</div>
		
	</div>
</template>

<script>
	import editCoupon from './components/layout/modal/editCoupon.vue';
	export default {
		components:{
			editCoupon,
		},
		data() {
			return {
				showForm:false,
				
				menus: [{
						name: '上架中',
						icon: 'iconshangjia',
						key: 'coupon_online',
						link: '/coupon'
					},
					{
						name: '下架过期',
						icon: 'iconxiajia',
						key: 'coupon_offline',
						link: '/coupon/offline'
					},
					{
						name: '优惠券记录',
						icon: 'iconhexiao',
						key: 'coupon_logs',
						link: '/coupon/logs'
					},
					
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
			addAct(){
				this.showForm = true;
			},
			closedAct(){
				this.showForm = false;
			}
			
		}
	}
</script>

<style>
	
</style>
